import React from "react"

import Layout from "../components/layout"
import Logo from "../components/logo"
import SEO from "../components/seo"
import { desc } from "../components/tagline.module.css"
import { container, content, errorTitle } from "../components/404.module.css"

const NotFoundPage = () => (
  <>
    <SEO title="PhillyCake – Page is not found" />
    <div className={container}>
      <div className={content}>
        <a href="/"><Logo siteTitle="PhillyCake" isSmall={true} /></a>
        <h1 className={[desc, errorTitle].join(' ')}>Page is not found.<br />(No even crumbs were left.)</h1>
      </div>
    </div>
  </>
)

export default NotFoundPage
